import axios from "axios";
import Metadata from "../helpers/Metadata";

export default class IdleChecker
{

    static touched = false;
    static timeout = null;

    // this is how often we check for interaction changes (in ms)
    static checkInterval = 10000; // every 10 seconds

    // this is how long the user has before being signed off (in ms)
    static signOutInterval = 10 * 60 * 1000; // sign out after 10 minutes by default

    static load () {

        if (['login', 'password.request'].includes(Metadata.get('route'))) {
            // do not do this on the login route
            return;
        }

        this.signOutInterval = Metadata.get('security.server_timeout', '10') * 60 * 1000;

        const events = [
            'mousemove', 'click', 'touchstart', 'dragend', 'scroll', 'focus', 'resize', 'keydown', 
        ];

        // every time one of the events above was triggered we set the flag to true
        events.forEach(eventName => {
            window.addEventListener(eventName, () => {
                this.touched = true;
            });
        });

        // every X seconds we check the status of our activity flag.
        // If the flag is false, there was no activity in the past interval. We then set a timeout of X seconds, that will 
        // sign the user off when triggered. This timeout will be cleared if in any of the next intervals the flag is set to true.
        
        setInterval(() => {

            if (!this.touched) {

                if (!this.timeout) {
                    this.timeout = setTimeout(() => {
                        
                        axios.post('/logout').then(response => {
                            document.location.href = '/login';
                        });

                    }, this.signOutInterval);
                }
                
            }
            else {

                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
            }

            this.touched = false;

        }, this.checkInterval);

    }


}
