// build css
import './style.scss'

// import required JS modules
import InteractionLoader from './js/components/InteractionLoader';
import ModalLoader from './js/components/ModalLoader';
import AudienceSelectionLoader from './js/pages/mail-campaign/audience-selection/AudienceSelectionLoader';
import StickyFormButtons from './js/components/StickyFormButtons';
import MessageFormController from './js/pages/mail-campaign/message/MessageFormController';
import Dropdown from './js/components/Dropdown';
import ToolTipText from './js/components/ToolTipText';
import MessageShowController from './js/pages/mail-campaign/message/MessageShowController';
import MetadataController from './js/pages/mail-campaign/meta-data/MetadataController';
import MessageSendController from './js/pages/mail-campaign/message/MessageSendController';
import LoginController from './js/pages/LoginController';
import IdleChecker from './js/components/IdleChecker';


window.addEventListener('load', () => {

    IdleChecker.load();

    LoginController.load();
    MessageShowController.load();
    MessageSendController.load();
    MetadataController.load();
    MessageFormController.load();
    
    // mail campaigns
    AudienceSelectionLoader.load();

    // modals
    ModalLoader.load();

    // generic interactions
    InteractionLoader.load();

    StickyFormButtons.load();

    Dropdown.load();

    ToolTipText.load();
    
    // ModalLoader.getInstance().get('audience-modal').show();


}); 