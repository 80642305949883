const fullDays = {
    nl: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
    en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
};

const shortDays = {
    nl: ['zo.', 'ma.', 'di.', 'wo.', 'do.', 'vr.', 'za.'],
    en: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
};

const shortestDays = {
    nl: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
    en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
};

const fullMonths = {
    nl: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
    en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

const shortMonths = {
    nl: ['jan.', 'feb.', 'mrt.', 'apr.', 'mei', 'jun.', 'jul.', 'aug.', 'sep.', 'okt.', 'nov.', 'dec.'],
    en: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'],
};


export default function isoFormat(date, format, locale) {

    if (typeof locale === 'undefined') {
        // find default locale
        locale = 'nl';
    }

    if (isNaN(date)) {
        if (typeof(date) === 'string') {
            return isoFormat(new Date(date), format);
        }
        return '** Invalid Date **';
    }

    if (typeof format === 'undefined') {
        return isoFormat(date, 'YYYY-MM-DD');
    }

    if (format.indexOf('[') !== -1) {

        const parts = [
            // part before the [
            isoFormat(date, format.substring(0, format.indexOf('[')), locale),
            
            // part between the [ and ]
            format.substring(format.indexOf('[') + 1, format.indexOf(']')),

            // part after the (first) ]
            isoFormat(date, format.substring(format.indexOf(']') + 1), locale),

        ];

        return parts.join('');

    }
    
    return format.replace(/(YYYY|YY|MMMM|MMM|MM|M|DD|D|dddd|ddd|dd|d|HH|H|hh|h|mm|m|ss|s|A|a|o)/g, part => {

        // years
        if (part === 'YYYY' || part === 'Y') {
            return date.getFullYear();
        }

        if (part === 'YY') {
            return String(date.getFullYear()).substring(2);
        }

        // months
        if (part === 'MMMM') {
            return fullMonths[locale][date.getMonth()];
        }

        if (part === 'MMM') {
            return shortMonths[locale][date.getMonth()];
        }

        if (part === 'MM') {
            return String(1 + date.getMonth()).padStart(2, '0');
        }

        if (part === 'M') {
            return 1 + date.getMonth();
        }


        // days
        if (part === 'DDD') {
            return Math.ceil((date - new Date(date.getFullYear(), 0, 1)) / 86400000);
        }

        if (part === 'DD') {
            return String(date.getDate()).padStart(2, '0');
        }

        if (part === 'D') {
            return date.getDate();
        }

        if (part === 'dddd') {
            return fullDays[locale][date.getDay()];
        }

        if (part === 'ddd') {
            return shortDays[locale][date.getDay()];
        }

        if (part === 'dd') {
            return shortestDays[locale][date.getDay()];
        }

        if (part === 'd') {
            return date.getDay();
        }


        // hours
        if (part === 'HH') {
            return String(date.getHours()).padStart(2, '0');
        }

        if (part === 'H') {
            return date.getHours();
        }

        if (part === 'hh') {
            return String(date.getHours() % 12 || 12).padStart(2, '0');
        }

        if (part === 'h') {
            return date.getHours() % 12;
        }

        // minutes
        if (part === 'mm') {
            return String(date.getMinutes()).padStart(2, '0');
        }

        if (part === 'm') {
            return date.getMinutes();
        }

        // seconds
        if (part === 'ss') {
            return String(date.getSeconds()).padStart(2, '0');
        }

        if (part === 's') {
            return date.getSeconds();
        }

        if (part === 'A') {
            return date.getHours() > 12 ? 'PM' : 'AM';
        }

        if (part === 'a') {
            return date.getHours() > 12 ? 'pm' : 'am';
        }

        if (part === 'o') {

            if ([1, 21, 31].includes(date.getDate())) {
                return 'st';
            }
            if ([2, 22].includes(date.getDate())) {
                return 'nd';
            }
            if ([3, 23].includes(date.getDate())) {
                return 'rd';
            }

            return 'th';
        }

        return '** unprocessable part: ' + part + ' **';

    });

}